<template>
    <div>
        <div class="top p-a padding-20" @click="onClickLeft">
            <i class="fa fa-angle-left white" style="font-size: 30px;"></i>
        </div>
        
        <div class="p-a" style="width:100%; top: 120px; z-index: 2;">
            <div class="text-align white" style="font-size: 30px;line-height: 60px;letter-spacing: 1px;">预报名查询</div>
            <div style="padding: 20px;">
                <van-form validate-first @submit="formSubmit">
                    <div class="bc-white br-10" style="padding: 20px 0;">
                        <van-field
                            v-model="name"
                            label="学生姓名"
                            name="name"
                            placeholder="学生姓名"
                            :rules="[{ pattern: nameVerification, message: '请填写正确的姓名'}]"
                        />
                        
                        <van-field
                            v-model="idCard"
                            label="身份证号"
                            name="idCard"
                            placeholder="身份证号"
                            :rules="[{ pattern:cardIdVerification, message: '请输入正确的身份证号' }]"
                        />
                    </div>
                    
                    <div style="margin: 25px 15px;">
                        <van-button round block type="primary" native-type="submit">查询</van-button>
                    </div>
                </van-form>
            </div>
            
        </div>
        <div class="bg-img w-100 p-a"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                name:'',
                idCard:'',
                nameVerification:/^[\u4E00-\u9FA5]{2,4}$/,
                cardIdVerification:/^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/
            }
        },
        methods:{
            formSubmit(){
                this.$toast.loading({
                    message: '查询中...',
                    forbidClick: true,
                });
                
                this.$api.register.studentSearch({name:this.name, idCard:this.idCard}).then((res) => {
                    if(res.data.status === false){
                        this.$toast.fail('未查询到该学生信息')
                        this.$storage.clear('studentSearch')
                    }else{
                        // 本地存储查询到的信息
                        this.$storage.setItem('studentSearch',res.data)
                        // 为了体验感，加了定时器
                        setTimeout(() => {
                            this.$router.push({
                                path:'/register/student-info'
                            })
                        }, 2000)
                    }
                })
            },
            onClickLeft() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style>
    .bg-img{
        background-image: url('/images/student_query_1.png');
        background-size: 100%;
        width: 100%;
        height: 100vh;
        z-index: 1;
    }
    .top{
        z-index: 2;
    }
</style>
